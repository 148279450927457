<template>
    <div ref="common-table" class="common-table">
        <el-table ref="table"
            :data="data"
            border
            fit
            v-loading="loading"
            empty-text="暂无数据"
            style="width: 100%"
        >
            <el-table-column label="序号" type="index" width="56" align="center" v-if="showIndex" :fixed="indexFixed?indexFixed:null"></el-table-column>
            
            <template v-for="(col, index) in columns">
                <!-- 操作列/自定义列 -->
                <slot v-if="col.slot" :name="col.slot"></slot>
                <!-- 普通列 -->
                <el-table-column v-else
                    :key="index"
                    :prop="col.prop ? col.prop : null"
                    :label="col.label ? col.label : null"
                    :width="col.width ? col.width : null"
                    :min-width="col.minWidth ? col.minWidth : null"
                    :sortable="col.sortable ? col.sortable : null"
                    :align="col.sortable ? col.sortable : 'center'"
                    :fixed="col.fixed ? col.fixed : null"
                    :show-overflow-tooltip="col.tooltip"
                    :formatter="col.formatter">
                </el-table-column>
            </template>
        </el-table>
        <div>
            <el-pagination 
                background
                layout="total, prev, pager, next, jumper"
                :page-size="pager.pageSize"
                :page-sizes="[1, 10, 20]"
                :total="pager.total"
                :current-page="pager.page"
                @current-change="handleCurrentChange">
            </el-pagination>
        </div>
        
    </div>
</template>
<script>
export default {
    name: "commonTable",
    props: {
        loading: {
            type: Boolean,
            default: ()=> false
        },
        showIndex: {
            type: Boolean,
            default: ()=> true
        },
        indexFixed: {
            type: String,
            default: ()=> null
        },
        data: Array,
        columns: Array,
        pager: {
            type: Object,
            default : function(){
                return { }
            }
        }
    },
    methods:{
        handleCurrentChange(val) {
            this.$emit('current-change', val);
        }
    }
}
</script>
<style lang="scss" scoped>
.common-table{
    .el-pagination{
        margin: 15px 0;
        text-align: right;
    }
}
</style>