<template>
	<div>
        <common-table :data="contractList" :columns="columns" :loading.sync="loading" indexFixed="left" :pager="pager" @current-change="handleCurrentChange">
            <el-table-column slot="operate" label="操作" align="center"  fixed="right">
                <template slot-scope="scope">
                    <!-- <el-link @click="viewDetail(scope.row)" icon="el-icon-view" type="primary">打印合同</el-link> -->
                    <router-link :to="`/contractmanage/detail/${scope.row.contract_id}`" class="el-link el-link--primary is-underline">
                        打印合同
                    </router-link>
                </template>
            </el-table-column>
        </common-table>
    </div>
</template>
<script>
import commonTable from '@/components/commonTable'
import { getContractList } from "@/api";
import { mapState } from 'vuex';

export default {
	name:'contractList',
	components:{
        commonTable
    },
    computed: {
        ...mapState([
            'supplyState'
        ])
    },
    data(){
        return {
            loading: false,
            columns: [
                {slot: 'operate'},
                {label: '合同编号', prop: 'contract_no', minWidth: '110px'},
                {label: '合同状态', prop: 'contract_status_name', width:'110px'},
                {label: '要求货物或服务', prop: 'description'},
                {label: '供应商名称', prop: 'supply_name', minWdith:'230px'},
                {label: '合同发布日期', prop: 'publish_datetime', width:156},
                {label: '采购员', prop: 'buyer'}
            ],
            pager: { 
                page_num:1, 
                pageSize:20, 
                total: 0
            },
            contractList: []
        }
    },
    created(){
        this.getList();
    },
    methods: {
        getList(){
            this.loading = true;
            getContractList(this.pager).then((res)=>{
                const { data } = res
                this.contractList = data.list;
                this.pager.total = parseInt(data.total_count);
            }).finally(()=>{
                this.loading = false
            })
        },
        handleCurrentChange(val){
            this.pager.page_num = val;
            this.getList();
        },
        viewDetail(row){
            this.$router.push({name: 'contractDetail', params: {id: row.contract_id}});
        },
        submit(row){
            alert('submit:', row)
        }
    }
}
</script>